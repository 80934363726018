/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/
body {
  position: relative;
  overflow-x: hidden;
  background-color: $color_bg_gray;
  background-position: center;
  background-size: cover;
  height: 100%;
  font-family: $font_text;
  font-optical-sizing: auto;

  #background0 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

html {
  height: 100%;
  font-size: 9px;

  @include tablet-portrait-and-up {
    font-size: 9px;
  }

  @include desktop-and-up {
    font-size: 11px;
  }
}

img {
  max-width: 100%;
}

// for safari draggable
[draggable="true"] {
  -khtml-user-drag: element;
}

a.disabled,
a[disabled] .btn.disabled,
.btn[disabled] button.disabled,
button[disabled] {
  cursor: not-allowed !important;
  opacity: 0.3 !important;
}

// NAV BAR

label.navbar-brand {
  font-size: 1.8rem;
  img {
    height: 3.6rem;
  }
}

// .conference-header-nav {
//   min-height: 62px;
// }

// TOP NAV LOGIN

.main-top-nav-header {
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
