/**
Copyright Compunetix Incorporated 2016-2019
All rights reserved
This document and all information and ideas contained within are the
property of Compunetix Incorporated and are confidential.

Neither this document nor any part nor any information contained in it may
be disclosed or furnished to others without the prior written consent of:
Compunetix Incorporated
2420 Mosside Blvd
Monroeville, PA 15146
http://www.compunetix.com

Author: lcheng
*/
@import "constants";
#wrapper {
  padding-left: 0;
  transition: $transition-ease-all-5;

  &.toggled {
    #sidebar-wrapper {
      width: 220px;
    }

    #page-content-wrapper {
      position: absolute;
      margin-right: -220px;
      margin-left: 220px;
    }
  }
}

#sidebar-wrapper {
  z-index: 1000;
  left: 220px;
  width: 0;
  height: 100%;
  margin-left: -220px;
  overflow-y: auto;
  overflow-x: hidden;
  background: $color_rangoon_gray_approx;
  transition: $transition-ease-all-5;
}

#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

#page-content-wrapper {
  width: 100%;
  padding-top: 17px;
  transition: $transition-ease-all-5;

  .container {
    margin-left: 50px;
  }
}

.dialog-container {
  top: 15rem;
  padding-bottom: 3rem;
}

.main-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 8rem;
  bottom: 3rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  overflow-y: auto;
}

.modal {
  .modal-body-header {
    a {
      i {
        font-size: 30px;
      }
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }
  }
}

#pdfviewer {
  width: 100%;
}

/* Mobile Portrait */
@media only screen
and (max-device-width: 736px)
and (orientation: portrait) {

}

/* Mobile Landscape */
@media only screen
and (max-device-width: 736px)
and (orientation: landscape) {

}
