ul.tree {
  list-style-type: none;
  background: url(/images/vline.png) repeat-y;
  margin: 0;
  padding: 0;

  ul {
    list-style-type: none;
    background: url(/images/vline.png) repeat-y;
    margin: 0;
    padding: 0;
    margin-left: 10px;
  }

  li {
    margin: 0;
    padding: 0 12px;
    line-height: 20px;
    background: url(/images/node.png) no-repeat;
    font-weight: bold;

    & > label {
      cursor: pointer;
    }

    &.last {
      background: #fff url(/images/lastnode.png) no-repeat;
    }

    &.selected {
      & > label {
        background: #ccc;
      }
    }
  }
}
