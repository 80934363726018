#previewIframe,
#style-list,
#flavorPanel {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}

