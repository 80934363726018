/**
* Copyright Compunetix Incorporated 2016-2022
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
       * Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: amaggi
*/

// This scss file is used to override css rules already present
// in other files in case you want to specify a particular behavior for VCC.
// The css rules must be inserted inside .vcc {...}
//
// For example the following rule:
//
// .vcc {
//   #conference-video-container {
//    top: 10rem;
//   }
// }
//
// will be compiled like this:
//
// .vcc #conference-video-container {
//   top: 10rem;
// }
//
// overwriting #conference-video-container in _conference.scss

@import "constants";
@import "media-queries";

.vcc {

  // PARTICIPANT PANEL

  .participant-panel, #settings-panel {
    width: $vcc-sidebar-width;
    top: 80px;
    bottom: 36px;

    @media (min-width: 992px) {
      top: 91px;
      bottom: 43px;
    }

    i.icon-large {
      padding: 0 3px;
    }

    .close {
      right: 1px;
      top: -13px;
    }

    .icon-close {
      font-size: 1.5rem;
    }

    label, a {
      padding: 0;
    }
  }

  #settings-panel {
    .close {
      right: 4px;
    }

    .icon-close {
      font-size: 1.6rem;
    }

    .dropdown-menu.show {
      padding: 5px 15px;
      text-align: center;
    }
  }


  // MODAL

  .modal-header {
    justify-content: center;

    .close {
      padding: 0;
      margin: 0;
      position: absolute;
      right: 7px;
      top: 2px;
      font-size: 2rem;
      font-weight: 300;
    }
  }

  .header-modal-body {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  // NAV BAR

  
.dropdown-toggle::after {
  vertical-align: 0.2rem;
  font-size: 1rem;
}

  // VIDEO LIST

  .left-sidebar-shown {
    @include tablet-landscape-and-up {
      left: $vcc-sidebar-width !important;
    }
  }

  .right-sidebar-shown {
    @include tablet-landscape-and-up {
      right: $vcc-sidebar-width !important;
    }
  }

  #conference-video-container {
    bottom: 36px !important;
    top: 80px;
    @media (min-width: 992px) {
      bottom: 44px !important;
      top: 91px;
    }
  }

  // LOOPBACK VIDEO

  #loopback-video-container {
    top: 83px !important;
    right: 3px !important;
    @include desktop-and-up {
      top: 95px !important;
    }
  }

  // CHATROOM

  #chatroom-window {
    width: $vcc-sidebar-width;
    top: 9rem;
    bottom:3rem;
    @media (min-width: 769px) {
      top: 8rem;
      bottom:3rem;
    }
  }

.guest-offline-status {
  margin-top: 220px;
  text-align: center;
  font-size: 2rem;
  padding: 10px;
}

}
