/**
* Copyright Compunetix Incorporated 2016-2023
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng, kbender
*/
@import "volume-control";
@import "signal-bars";
@import "constants";
@import "media-queries";

#easyrtcErrorDialog {
  display: none !important;
}

.transit {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3,
  // $transition-4,$transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: left 1s, top 1s, right 1s, bottom 1s, width 1s, height 1s, transform 1s;
}

.hidden {
  background-color: transparent;
  border-color: 2px solid $blue;
}

.boxCommon {
  position: absolute;
}

.closeButton {
  display: none;
}

.thumbCommon {
  z-index: 2;
  // box-shadow: 5px 5px 5px $black;
}

#controlArea {
  background-color: $color_flush_orange_approx;
}

body {
  margin-left: 0;
  margin-top: 0;
}

#killButton {
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

#muteButton {
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

#textentryBox {
  border: 2px solid $color_mint_green_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 10px;
  z-index: 5;
  background-color: $white;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5,
  // $shadow-6,  $shadow-7,  $shadow-8,  $shadow-9,  $shadow-10)
  box-shadow: 5px 5px 5px $black;
  font-family: $font_0, $font_1, $font_2;
}

#textentryField {
  margin: 20px;
}

#textentrySubmit {
  font-size: larger;
}

#textentryCancel {
  font-size: larger;
}

.conference-header-nav {
  top: 2rem;
  min-height: 4rem;
  line-height: 3rem;
  background-color: $navbar_color;
  font-family: $font_link;
  border-style: solid;
  border-width: 0;
  padding: 1px;
  #menu-button {
    color: #fff;
    margin-top: 0.5rem;
  }

  a,
  h1,
  button {
    &.nav-link {
      display: inline-block;
      color: $white;

      &:hover {
        color: $color_celeste_approx;
        outline: none;
      }

      &:focus {
        outline: none;
      }

      &:active,
      &.active {
        color: $color_rangoon_gray_approx;
      }

      margin: 0px 0px !important;
      text-align: center;

      i {
        font-size: 2rem;
      }

      img {
        height: 2rem;
        width: 2rem;
      }

      label {
        margin-bottom: 0px;
        cursor: pointer;
        font-weight: 400;
        font-size: 1rem;
        margin-top: 1px;

        @include desktop-and-up {
          font-size: 0.8rem;
        }

        &.badge {
          height: 13px;
        }
        &.badge-content {
          font-size: 1.5em;
          background-color: rgba(0,0,0,0.5);
          border-radius: 5px;
          padding: 0em 20px;
          line-height: 2em;
          color: #fff;
          font-weight: 300;
          i {
            font-size: inherit;
          }
        }
      }

      &.btn {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      &.toggle-nav-link {
        font-size: 1rem;

        i {
          font-size: 2.5rem;
        }
      }
    }

    &.navbar-brand {
      padding-top: 0;
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
      cursor: default;
      margin: 0px;
      line-height: 2em;
      img {
        height: 2em;
      }
    }
  }
}


@media (min-width: 545px) {
  .conference-header-nav label.navbar-brand {
      font-size:2rem;
      margin-right:0;
  }

  .conference-header-nav img.navbar-brand {
      height: 4rem
  }
}

@media (min-width: 992px) {
  
}

#menu-button.mobile {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 8;

  svg {
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  }

  .tag {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    font-size: 9px;
  }
}

#voice-conference-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  overflow: hidden;
}

#conference-video-container {
  left: 0px;
  right: 0px;
  top: 7.9rem;
  bottom: 3.8rem;
  background-image: url(../images/irongrip.png);
  z-index: 3;
  overflow: hidden;
  #main-video-container {
    overflow-y: hidden;
    height: 100%;
  }
  #video-list-container, .side-videos-container {
    z-index: 9;
    overflow-y: auto;
    right: 0px;
  }
  .collapse-video-container {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow-y: auto;
  }
  .Blink {
    position: absolute;
    z-index: 999;
    top: 0.25rem;
    left: 50%;
    font-size: medium;
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }
  .pip-mobile {
    top: 0;
    left: 0;
    width: 170px;
    height: auto;

    .video-view-control {
      display: none !important;
    }
  }
}

#conference-video-container.mobile {
  top: 0 !important;
  bottom: 0 !important;
}

.video-container {
  &.minimized {
    height: 2.25rem !important;

    video {
      width: 0px !important;
      height: 0px !important;
    }
  }
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.video {
  z-index: 3;
  width: 100%;
  display: inline-block;
}

.rotate-icon {
  font-size: 12px;
  position: absolute;
  right: 30px;
  bottom: 2px;
  color: #b2b2b2;
}

.rotate-control {
  float: right;
  position: relative;
  bottom: 40px;
  right: 10px;
}

.control-icon
{
  color: white;
  transition: all ease-in-out 350ms;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.6);
  display: inline;
  padding: 2px;
}

.control-bar
{
  position: absolute;
  left: 2%;
  top: 0px;
  z-index: 1000;
}

.fit-cover {
  object-fit: cover;
}

.video-view-control {
  position: absolute;
  z-index: 4;

  .control-link {
    color: white;
    background: $color_gray_opacity;
    border: 1px solid $color_white_opacity;

    &:hover,
    &:active {
      background-color: $black;
    }
  }
}

.join-conference-container {
  position: absolute;
  top: 0rem;

  @include mobile-landscape-and-below {
    width: 100%;
  }

  @include desktop-and-up {
    top: 10rem;
  }

  .container {
    max-width: 35rem;
    padding: 30px 15px;
    margin-bottom: 15px;

    &.title-container {
      max-width: 100%;

      img {
        max-width: 35rem;
      }
    }
  }

  &.fullScreenMode {
    top: 5rem;
    .container {
      padding: 0px 15px;
      h1,
      img {
        margin-bottom: 0em;
      }
      .title-container {
        padding: 0px;
        margin-bottom: 0px;
      }
    }
  }

  .join-button {
    padding: 1rem 5px;
    margin-top: 30px;
  }

  .options-button {
  }

  .form-group {
    .form-control {
      padding: 1.5rem 0.75rem;
    }
  }

  .share-container {
    margin-top: 30px;

    i {
      font-size: 30px;
      position: absolute;
      padding: 14px;
    }

    input {
      padding: 1rem 81px 1rem 56px;
      display: inline-block;
    }

    button {
      position: absolute;
      right: 14px;
      top: 3px;
    }
  }
}

.join-conference-container.mobile {
  .btn, input, .alert {
    font-size: 1.5rem;
  }
}

#easyrtcErrorDialog {
  margin-top: 110px;
}

#settings-popover-content,
#participants-popover-content,
#chatbox-popover-content {
  width: 260px;
  white-space: nowrap;

  input {
    width: 150px;
  }

  .display-container {
    .name-button {
      width: 100%;
      max-width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.full-screen {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

#name-tag-container {
  position: absolute;
  top: 10em;
  color: $white;
  left: 15px;
  z-index: 3;
}

#video-scene {
  overflow: hidden;
  z-index: 1;
  height: 100%;
  .video {
  }
}

.collapse-to-left-half {
  right: 50% !important;
}

.left-sidebar-shown {
  @include tablet-landscape-and-up {
    left: $sidebar-width !important;
  }
}

.right-sidebar-shown {
  @include tablet-landscape-and-up {
    right: $sidebar-width !important;
  }
}

#conference-fullscreen-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #e6e3e3;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  overflow-y: auto;
}

#in-page-panel,
#in-page-canvas {
  position: absolute;
  top: 85px;
  bottom: 3.5rem;
  left: 50%;
  background-color: $white;
  z-index: 3;
  overflow: auto;

  @include desktop-and-up {
    top: 92px;
  }
}

#full-page-panel {
  position: absolute;
  top: 6rem;
  bottom: 3.5rem;
  left: 0px;
  right: 0px;
  background-color: $white;
  z-index: 3;
  overflow: auto;

  @include tablet-landscape-and-up {
    top: 7rem;
  }
}

#in-page-canvas {
  z-index: -1;
}

#map-view,
#map-image {
  width: 100%;
  height: 100%;
  max-width: 640px;
  max-height: 640px;
}

#map-image {
  position: absolute;
  top: 0px;
  z-index: -1;
}

#connect-screen {
  #waiting-video-container {
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    left: 0px;
    right: 0px;
    overflow: hidden;

    @include tablet-landscape-and-up {
      top: 7rem;
      padding-bottom: 6rem;
      &.expand-to-bottom {
        padding-bottom: 3rem;
      }
    }

    .waiting-media {
      width: 100%;
      height: 100%;

      &.waiting-image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &.full-screen {
      top: 0px;
      bottom: 0px;
    }
  }

  #connecting-button {
    bottom: 8rem;
    position: absolute;
    width: 100%;
    z-index: 9;
    padding: 0 3.5rem;
  }
}

.guest-wait-container {
  margin-top: 7rem;
  font-weight: 300;

  h1 {
    font-weight: 300;
  }
}

.guest-queue-container {
  margin-top: 7rem;
  font-weight: 300;

  h1 {
    font-weight: 300;
  }

}

.guest-queue-selection {
  text-align: center;

  .guest-queue {
    font-size: 1.5em;
  }
}

.popover {
  max-width: 345px;
}

#mic-dropdown,
#camera-dropdown,
#speaker-dropdown {
  label {
    width: 15rem;
    white-space: normal;
  }
}

.mobile-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.volume-adjust {
  position: absolute;
  width: 60px;
  bottom: 20px;
  right: 25px;
  z-index: 10;
}

.volume-button {
  cursor: pointer;
}

.volume-button i {
  font-size: 8.5rem;
}

.volume-value {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.volume-value i {
  width: 18px;
}

.volume-value .value {
  user-select: none;
  min-width: 36px;
  text-align: right;
}

a.volume-button {
  color: inherit;
}

a.volume-button i:active {
  color: #b2b2b2;
}

.fa-caret-down {
  position: relative;
  bottom: 27px;
  left: -2px;
}

.fa-caret-up {
  position: relative;
  top: 30px;
  left: -2px;
}

#map {
  height: 100%;
  width: 100%;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);

  .spinner {
    width:30px;
  }

  .message {
    color: white;
    font-size: 1.5rem;
    margin-top: 1rem;
    animation: 1s ease 0s normal forwards 1 fadein;
    -webkit-animation: 1s ease 0s normal forwards 1 fadein;
  }

  @keyframes fadein {
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
  }
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
