/**
Copyright Compunetix Incorporated 2016-2017
All rights reserved
This document and all information and ideas contained within are the
property of Compunetix Incorporated and are confidential.

Neither this document nor any part nor any information contained in it may
be disclosed or furnished to others without the prior written consent of:
Compunetix Incorporated
2420 Mosside Blvd
Monroeville, PA 15146
http://www.compunetix.com

Author: lcheng
*/
#virtualKeyboard {
  width: 100%;
  text-align: center;
  bottom: 60px;
  position: fixed;
  left: 0%;
  background-color: $navbar_color-dark;

  /**border-top:3px solid #000;*/
  #keyboard {
    padding: 10px;
    width: 850px;
    margin: 0 auto;
    height: 288;
    display: block;
    padding-left: 25px;
    display: block;
    /**position:absolute;*/
    bottom: 0px;
    left: 0px;

    #keyboardHeader {
      position: relative;
    }

    #keyboardHeader div {
      color: #fff;
      background-color: #7b8490;
      padding: 3px;
      padding-left: 15px;
      padding-right: 15px;
      position: absolute;
      cursor: pointer;
      right: -10px;
      top: -40px;
    }

    .closex {
      font-weight: bolder;
      color: #000;
    }

    #keyboardCapitalLetter {
      display: none;
    }

    #keyboardSmallLetter {
      display: block;
    }

    #keyboardNumber {
      display: none;
    }

    #keyboardSymbols {
      display: none;
    }

    .button {
      width: 60px;
      height: 60px;
      background-color: #fff;
      position: relative;
      float: left;
      margin-right: 9px;
      margin-top: 10px;
      cursor: pointer;
      font-size: 1.9em;
      /**box shadow*/
      -webkit-box-shadow: 0px 1px 3px #000000;
      -moz-box-shadow: 0px 1px 3px #000000;
      box-shadow: 0px 1px 3px #000000;
      /**box radius*/
      -moz-border-radius: 5px;
      border-radius: 5px;
      /**gradient*/
      background: #ffffff;
      /**old browsers */
      background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
      /**firefox */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #e5e5e5));
      /**webkit */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e5e5e5', GradientType=0);

      /**ie */
      &:hover {
        border-color: rgba(82, 168, 236, 0.8);
        outline: 0;
        outline: thin dotted 9;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
      }
    }

    .buttonDown {
      background: none;
      background-color: #c9cdd5;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    .button_a {
      margin-left: 26px;
    }

    .button_dash {
      margin-left: 26px;
    }

    .button_underscore {
      margin-left: 26px;
    }

    .button_enter {
      width: 103px;
      font-size: 1.3em;
    }

    .button_del {
      width: 103px;
      font-size: 1.3em;
    }

    .button_smallletter {
      font-size: 1.3em;
    }

    .button_capitalletterleft {
      font-size: 1.3em;
    }

    .button_capitalletterright {
      width: 129px;
      font-size: 1.3em;
    }

    .button_numberleft {
      width: 172px;
      font-size: 1.3em;
      margin-left: 26px;
    }

    .button_numberright {
      width: 129px;
      font-size: 1.3em;
    }

    .button_space {
      width: 376px;
    }

    .button_symbolsleft {
      width: 172px;
      font-size: 1.3em;
      margin-left: 26px;
    }

    .button_symbolsright {
      width: 129px;
      font-size: 1.3em;
    }

    .key {
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 12px;
      text-shadow: 0px 1px 1px #ffffff;
      filter: dropshadow(color=#ffffff, offx=0, offy=1);
    }

    .key_del {
      padding-top: 18px;
    }

    .key_enter {
      padding-top: 18px;
    }

    .key_smallletter {
      padding-top: 18px;
    }

    .key_capitalletterleft {
      padding-top: 18px;
    }

    .key_capitalletterright {
      padding-top: 18px;
    }

    .key_number {
      padding-top: 18px;
    }

    .key_symbols {
      padding-top: 18px;
    }

    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}

#username_keyboard {
  top: auto;
  bottom: 50px;
  width: 100%;

  .ui-keyboard-button,
  .ui-keyboard-preview {
    font-size: 2rem;
  }
}
