/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/
.bg-white {
  background-color: $white;
  color: $default;

  a:not(.btn) {
    color: $default;

    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }
  }
}

.bg-white-opacity {
  background-color: $color_white_opacity;
  color: $default;

  a:not(.btn) {
    color: $default;

    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }
  }
}

.bg-inverse {
  a:not(.btn) {
    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }
  }
}

.bg-topnav {
  background-color: $navbar_color;
  color: $white;

  a:not(.btn) {
    color: $white;

    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }
  }
}

.bg-topnav-dark {
  background-color: $navbar_color_dark;
  color: $white;

  a:not(.btn) {
    color: $white;

    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }
  }
}

.bg-footer-nav {
  background-color: $footer_color;

  a:not(.btn) {
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

.bg-light-blue {
  background-color: $light_bg_color;
  color: $white;

  a:not(.btn) {
    color: $white;

    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }
  }
}

.bg-gray-opacity {
  background: $color_gray_opacity;
  color: $white;

  a:not(.btn) {
    color: $white;

    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }
  }
}

.text-white,
.text-white-default {
  color: $white;

  &:hover,
  &:focus {
    color: $color_celeste_approx;
  }
}

.text-color-inherit {
  color: inherit !important;

  &:hover,
  &:focus {
    color: inherit !important;
  }
}

.text-default {
  color: #373a3c !important;
}

a.text-default:focus,
a.text-default:hover {
  color: #1f2021 !important;
}

.bg-transparent {
  background: none !important;
  background-color: transparent !important;
}

.text-dark {
  color: #000 !important;
}