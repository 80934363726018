/**
* Copyright Compunetix Incorporated 2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/

@use 'sass:math';

$control-button-width: 56px;
$control-button-height: 56px;
$slider-track-thickness: 8px;
$slider-thumb-size: 30px;
$slider-height: 150px;
$color-theme: #3d3d4a;
$color-track: #343440;

@mixin track {
  border: none;
  background: $color-track;
  width: $slider-track-thickness;
  border-color: $color-track;
  border-radius: 10px;
  box-shadow: 0 0 0 2px $color-theme;
}

@mixin thumb {
  width: $slider-thumb-size;
  height: $slider-thumb-size;
  opacity: 0;
}

.video-control {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 3;

  .control-link {
    color: white;
    background: $color_gray_opacity;
    border: 1px solid $color_white_opacity;

    &:hover,
    &:active {
      background-color: $black;
    }
  }
}

#video-controls {
  .control {
    position: absolute;
    z-index: 3;
    white-space: nowrap;
    border-radius: $control-button-height;
    background-color: rgba(0, 0, 0, 0.5);

    i {
      font-size: 28px;
    }

    &.position-1 {
      top: 50%;
      left: 2rem;
      margin-top: math.div($control-button-width, 2) * -1;
    }

    &.position-2 {
      bottom: 2rem;
      left: 2rem;
    }

    &.position-3 {
      bottom: 2rem;
      left: 50%;
      margin-left: math.div($control-button-width,2) * -1;
    }

    &.position-4 {
      bottom: 2rem;
      right: 2rem;
    }

    &.position-5 {
      top: 50%;
      right: 2rem;
      margin-top:  math.div($control-button-height, 2) * -1;
    }

    label {
      margin-top: 0.5rem;
    }

    .range-slider {
      width: $control-button-width;
      position: relative;
      text-align: center;
      height: $slider-height;
      max-height: 100%;
      display: none;

      &__thumb {
        position: absolute;
        left: math.div($control-button-width, 2) - math.div($slider-thumb-size, 2);
        width: $slider-thumb-size;
        height: $slider-thumb-size;
        line-height: $slider-thumb-size;
        background: white;
        color: #777;
        font-size: 50%;
        box-shadow: 0 0 0 4px $color-theme;
        border-radius: 50%;
        pointer-events: none;
      }

      &__bar {
        left: math.div($control-button-width, 2) - math.div($slider-track-thickness, 2);
        bottom: 0;
        position: absolute;
        background: linear-gradient(dodgerblue, blue);
        pointer-events: none;
        width: $slider-track-thickness;
        border-radius: 10px;
      }

      input[type="range"][orient="vertical"] {
        position: relative;
        margin: 0;
        height: 100%;
        width: 100%;
        display: inline-block;
        position: relative;
        writing-mode: bt-lr;
        -webkit-appearance: slider-vertical;

        &::-webkit-slider-runnable-track,
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
        }

        &::-webkit-slider-runnable-track {
          @include track;
        }

        &::-moz-range-track {
          @include track;
        }

        &::-ms-track {
          @include track;
          color: transparent;
          height: 100%;
        }

        &::-ms-fill-lower,
        &::-ms-fill-upper,
        &::-ms-tooltip {
          display: none;
        }

        &::-webkit-slider-thumb {
          @include thumb;
        }

        &::-moz-range-thumb {
          @include thumb;
        }

        &::-ms-thumb {
          @include thumb;
        }
      }
    }
  }
}
