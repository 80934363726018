.cal-week-day-view {
  display: flex;
  margin-left: 2px;
  margin-right: 2px;

  .cal-day-view-host {
    display: flex;
    flex: 1;
    text-align: center;
    padding: 5px;
    min-width: 200px;
  }

  .cal-day-view-host:not(:last-child) {
    border-right: 1px solid #e1e1e1;
  }

  .cal-day-view {
    width: 100%;
  }
}

.cal-week-view {
  .cal-day-headers {
    display: flex;
    margin-bottom: 3px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .cal-day-headers .cal-header {
    flex: 1;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    border: 1px solid #e1e1e1;
    min-width: 200px;
  }

  .cal-day-headers .cal-header:not(:last-child) {
    border-right: 1px solid #e1e1e1;
  }

  .cal-day-headers .cal-header:hover:not(.cal-open),
  .cal-day-headers .cal-drag-over {
    background-color: #ededed;
  }

  .cal-day-headers span {
    font-weight: 400;
    opacity: 0.5;
  }

  .cal-events-row {
    position: relative;
    height: 33px;
  }

  .cal-event-container {
    display: inline-block;
    position: absolute;
  }

  .cal-event {
    padding: 0 10px;
    font-size: 12px;
    margin-left: 2px;
    margin-right: 2px;
    height: 30px;
    line-height: 30px;
    background-color: #d1e8ff;
    border: 1px solid #1e90ff;
    color: #1e90ff;
  }

  .cal-event-title:link {
    color: currentColor;
  }

  .cal-draggable {
    cursor: move;
  }

  .cal-starts-within-week .cal-event {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .cal-ends-within-week .cal-event {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .cal-open {
    background-color: #dfea9c;
  }

  .cal-header.cal-today:not(.cal-open) {
    background-color: #a0ea9c;
  }

  .cal-header.cal-weekend span {
    color: #8b0000;
  }

  .cal-event,
  .cal-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.cal-month-view {
  .cal-header {
    text-align: center;
    font-weight: bolder;
  }

  .cal-cell-row:hover {
    background-color: #fafafa;
  }

  .cal-header .cal-cell {
    padding: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }

  .cal-open {
    background-color: #dfea9c;
  }

  .cal-cell-row .cal-cell:hover:not(.cal-today):not(.cal-open) {
    background-color: #ededed;
  }

  .cal-days {
    border: 1px solid #e1e1e1;
    border-bottom: 0;
  }

  .cal-cell-top {
    min-height: 78px;
    flex: 1;
  }

  .cal-cell-row {
    display: flex;
  }

  .cal-cell {
    float: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .cal-day-cell {
    min-height: 100px;
  }

  .cal-day-cell:not(:last-child) {
    border-right: 1px solid #e1e1e1;
  }

  .cal-days .cal-cell-row {
    border-bottom: 1px solid #e1e1e1;
  }

  .cal-day-badge {
    margin-top: 18px;
    margin-left: 10px;
    background-color: #b94a48;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: white;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 10px;
  }

  .cal-day-number {
    font-size: 1.2em;
    font-weight: 400;
    opacity: 0.5;
    margin-top: 15px;
    margin-right: 15px;
    float: right;
    margin-bottom: 10px;
  }

  .cal-events {
    flex: 1;
    align-items: flex-end;
    margin: 3px;
    line-height: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .cal-event {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
    background-color: #1e90ff;
    border-color: #d1e8ff;
    color: #fff;
  }

  .cal-event-title:link {
    color: currentColor;
  }

  .cal-day-cell.cal-in-month.cal-has-events {
    cursor: pointer;
  }

  .cal-day-cell.cal-out-month .cal-day-number {
    opacity: 0.1;
    cursor: default;
  }

  .cal-day-cell.cal-weekend .cal-day-number {
    color: darkred;
  }

  .cal-day-cell.cal-today:not(.cal-open) {
    background-color: #a0ea9c;
  }

  .cal-day-cell.cal-today .cal-day-number {
    font-size: 1.9em;
  }

  .cal-day-cell.cal-drag-over {
    background-color: darken(#ededed, 10%) !important;
  }

  .cal-open-day-events {
    display: none;
    visibility: hidden;
    /**padding: 15px;
color: white;
background-color: #555;
box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5); */
  }

  .cal-open-day-events .cal-event {
    display: none;
    visibility: hidden;
    /**position: relative;
top: 2px; */
  }

  .cal-out-month {
    .cal-day-badge,
    .cal-event {
      opacity: 0.3;
    }
  }
}

.cal-day-view {
  .cal-hour-rows {
    width: 100%;
    border: solid 1px #e1e1e1;
    overflow-x: scroll;
    position: relative;
  }

  .cal-hour:nth-child(odd) {
    background-color: #fafafa;
  }

  .cal-hour-segment::after {
    content: "\00a0";
  }

  .cal-hour:not(:last-child) .cal-hour-segment,
  .cal-hour:last-child :not(:last-child) .cal-hour-segment {
    border-bottom: thin dashed #e1e1e1;
  }

  .cal-time {
    font-weight: bold;
    padding-top: 5px;
    width: 70px;
    text-align: center;
  }

  .cal-hour-segment.cal-after-hour-start {
    .cal-time {
      display: none;
    }
  }

  .cal-hour-segment:hover:not(.cal-open):not(.cal-today),
  .cal-drag-over .cal-hour-segment {
    background-color: #ededed;
  }

  .cal-event-container {
    position: absolute;
    width: 60% !important;
  }

  .cal-event {
    padding: 5px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    box-sizing: border-box;
    background-color: #d1e8ff;
    border: 1px solid #1e90ff;
    color: #1e90ff;
  }

  .cal-event-title:link {
    color: currentColor;
    width: 100%;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
  }

  .cal-draggable {
    cursor: move;
  }

  .cal-starts-within-day .cal-event {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .cal-ends-within-day .cal-event {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .cal-all-day-event {
    padding: 8px;
    border: solid 1px;
  }

  .cal-open {
    background-color: #dfea9c;
  }

  .cal-today:not(.cal-open) {
    background-color: #a0ea9c;
  }
}

.cal-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 11px;
  word-wrap: break-word;
  opacity: 0.9;
}

.cal-tooltip.cal-tooltip-top {
  padding: 5px 0;
  margin-top: -3px;
}

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.cal-tooltip.cal-tooltip-right {
  padding: 0 5px;
  margin-left: 3px;
}

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.cal-tooltip.cal-tooltip-bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.cal-tooltip.cal-tooltip-left {
  padding: 0 5px;
  margin-left: -3px;
}

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.cal-tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.cal-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.cal-open {
  background-color: #dfea9c;
}

.calendar-view {
  overflow-y: auto;
}