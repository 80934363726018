/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/

@font-face {
  font-family: "Glyphicons Halflings";
  src: url("../fonts/glyphicons/glyphicons-halflings-regular.eot");
  src: url("../fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/glyphicons/glyphicons-halflings-regular.woff2") format("woff2"),
    url("../fonts/glyphicons/glyphicons-halflings-regular.woff") format("woff"),
    url("../fonts/glyphicons/glyphicons-halflings-regular.ttf") format("truetype"),
    url("../fonts/glyphicons/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

@font-face {
  font-family: "bellslim_regular";
  src: url("../fonts/bellslim/bellslim_regular-webfont.woff") format("woff");
}

// LATO

@font-face {
  font-family: "Lato";
  font-weight: 100;
  src: url("../fonts/Lato/Lato-Thin.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/Lato/Lato-ThinItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 300;
  src: url("../fonts/Lato/Lato-Light.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Lato/Lato-LightItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: url("../fonts/Lato/Lato-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Lato/Lato-Italic.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 700;
  src: url("../fonts/Lato/Lato-Bold.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Lato/Lato-BoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 900;
  src: url("../fonts/Lato/Lato-Black.ttf") format("truetype")
}

@font-face {
  font-family: "Lato";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Lato/Lato-BlackItalic.ttf") format("truetype")
}

// ROBOTO

@font-face {
  font-family: "Roboto";
  font-weight: 100;
  src: url("../fonts/Roboto/Roboto-Thin.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/Roboto/Roboto-ThinItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: url("../fonts/Roboto/Roboto-Light.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Roboto/Roboto-LightItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Roboto/Roboto-Italic.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  src: url("../fonts/Roboto/Roboto-Black.ttf") format("truetype")
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Roboto/Roboto-BlackItalic.ttf") format("truetype")
}

// POPPINS

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  src: url("../fonts/Poppins/Poppins-Thin.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-LightItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-Italic.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: url("../fonts/Poppins/Poppins-Black.ttf") format("truetype")
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype")
}

// MERRIWEATHER

@font-face {
  font-family: "Merriweather";
  font-weight: 300;
  src: url("../fonts/Merriweather/Merriweather-Light.ttf") format("truetype")
}

@font-face {
  font-family: "Merriweather";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Merriweather/Merriweather-LightItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Merriweather";
  font-weight: 400;
  src: url("../fonts/Merriweather/Merriweather-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "Merriweather";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Merriweather/Merriweather-Italic.ttf") format("truetype")
}

@font-face {
  font-family: "Merriweather";
  font-weight: 700;
  src: url("../fonts/Merriweather/Merriweather-Bold.ttf") format("truetype")
}

@font-face {
  font-family: "Merriweather";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Merriweather/Merriweather-BoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: "Merriweather";
  font-weight: 900;
  src: url("../fonts/Merriweather/Merriweather-Black.ttf") format("truetype")
}

@font-face {
  font-family: "Merriweather";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Merriweather/Merriweather-BlackItalic.ttf") format("truetype")
}

// MONTSERRAT

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: normal;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: italic;
  }
}

@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: Montserrat;
    src: url("../fonts/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
    font-weight: normal;
  }
}

// OPEN SANS

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "OpenSans";
    src: url("../fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: normal;
  }

  @font-face {
    font-family: "OpenSans";
    src: url("../fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: italic;
  }
}

@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: OpenSans;
    src: url("../fonts/Open_Sans/static/OpenSans-Regular.ttf") format("truetype");
    font-weight: normal;
  }
}

// OSWALD

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Oswald";
    src: url("../fonts/Oswald/Oswald-VariableFont_wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: normal;
  }
}

@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: Oswald;
    src: url("../fonts/Oswald/static/Oswald-Regular.ttf") format("truetype");
    font-weight: normal;
  }
}

// RALEWAY

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-VariableFont_wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: normal;
  }

  @font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway/Raleway-Italic-VariableFont_wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: italic;
  }
}

@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: Raleway;
    src: url("../fonts/Raleway/static/Raleway-Regular.ttf") format("truetype");
    font-weight: normal;
  }
}

// SOURCE SERIF

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "SourceSerif4";
    src: url("../fonts/Source_Serif_4/SourceSerif4-VariableFont_opsz,wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: normal;
  }

  @font-face {
    font-family: "SourceSerif4";
    src: url("../fonts/Source_Serif_4/SourceSerif4-Italic-VariableFont_opsz,wght.ttf") format("truetype-variations");
    font-weight: 100 1000;
    font-style: italic;
  }
}

@supports not (font-variation-settings: normal) {
  @font-face {
    font-family: SourceSerif4;
    src: url("../fonts/Source_Serif_4/static/SourceSerif4-Regular.ttf") format("truetype");
    font-weight: normal;
  }
}

.large {
  font-size: 125%;
}
.x-large {
  font-size: 150%;
}

.xx-large {
  font-size: 200%;
}

.x-small {
  font-size: 60%;
}

.xx-small {
  font-size: 50%;
}

.text-thin {
  font-weight: 100;
}

.text-light {
  font-weight: 300;
}

.text-bold {
  font-weight: 700;
}

.text-black {
  font-weight: 900;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.5;
}

h1,
.h1 {
  font-size: 2.5em;
}

h4,
.h4 {
  font-size: 1.5em;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5em 1.5em;
  font-size: 1.5em;
  border-radius: 0.3em;
}
