/**
Copyright Compunetix Incorporated 2016-2017
All rights reserved
This document and all information and ideas contained within are the
property of Compunetix Incorporated and are confidential.

Neither this document nor any part nor any information contained in it may
be disclosed or furnished to others without the prior written consent of:
Compunetix Incorporated
2420 Mosside Blvd
Monroeville, PA 15146
http://www.compunetix.com

Author: lcheng
*/
.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid $color_apple_approx;
  /**green */
}

.ng-invalid:not(form):not(div) {
  border-left: 5px solid $color_apple_blossom_approx;
  /**red */
}

input[type="range"] {
  /**removes default webkit styles*/
  -webkit-appearance: none;
  /**required for proper track sizing in FF*/
  background: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $color_primary_blue;
  margin-top: -4px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type="range"]::-moz-range-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $color_primary_blue;
}

/**hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  height: 5px;
  /**remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /**leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /**remove default tick marks*/
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $color_primary_blue;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #888;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #ccc;
}
