/**
Copyright Compunetix Incorporated 2016
All rights reserved
This document and all information and ideas contained within are the
property of Compunetix Incorporated and are confidential.

Neither this document nor any part nor any information contained in it may
be disclosed or furnished to others without the prior written consent of:
Compunetix Incorporated
2420 Mosside Blvd
Monroeville, PA 15146
http://www.compunetix.com

Author: lcheng
*/
#home-section-1,
#home-section-3 {
  padding: 30px 30px 100px 30px;

  h3 {
    margin: 50px 15px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }

  h5 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bolder;
  }

  .home-section-block {
    max-width: 600px;
    display: inline-block;
  }
}

#home-section-3 {
  h1 {
    max-width: 600px;
    padding: 30px;
  }

  p {
    padding: 0px 30px;
  }

  .btn {
    margin: 5px;
  }
}

#home-section-1-bg-image,
#home-section-0-bg-image,
#home-section-2-bg-image,
#home-section-3-bg-image {
  position: absolute;
  top: 80px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: auto;
  background-size: 100%;
  z-index: -1;
}

#home-section-0-bg-image {
  background-image: url("/images/bank-demo-bg-0.png");
}

#home-section-1-bg-image {
  background-image: url("/images/bank-demo-bg-1.png");
}

#home-section-2-bg-image {
  background-image: url("/images/Fall_cropped.jpg");
}

#home-section-3-bg-image {
  background-image: url("/images/Family_cropped.jpg");
}

#home-section-2,
#home-section-4 {
  padding: 30px 100px 30px 30px;

  h1 {
    font-size: 4rem;
    font-family: "Poppins", sans-serif;
    font-weight: bolder;
  }

  h2 {
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: bolder;
  }

  ol.circle-list {
    list-style-type: none;
    margin: 0;
    margin-left: 4em;
    padding: 0;
    counter-reset: li-counter;
  }

  ol.circle-list > li {
    position: relative;
    margin-bottom: 20px;
    padding-left: 0.5em;
    min-height: 3em;
  }

  ol.circle-list > li:before {
    position: absolute;
    top: 0;
    left: -1.33em;
    width: 1.2em;
    height: 1.2em;
    font-size: 2em;
    line-height: 1;
    text-align: center;
    color: #f5f5f5;
    border: 3px solid #c5c5c5;
    border-radius: 50%;
    content: counter(li-counter);
    counter-increment: li-counter;
  }
}

#home-section-4 {
  h2,
  p,
  .image-button-list {
    padding: 12px;
  }

  .image-button-list {
    img {
      height: 128px;
      width: 128px;
      margin-bottom: 15px;
      background-color: white;
      border-radius: 64px;
    }
  }

  #news-slide {
    p {
      white-space: normal;
      padding: 0px 30px;
    }

    img {
      max-width: 100px;
      background-color: white;
      border-radius: 50px;
    }
  }
}

#loginModal {
  .container-fluid {
    text-align: center;
    padding: 20px 80px 20px 80px;

    .form-header {
      a {
        i {
          font-size: 30px;
        }

        cursor: pointer;

        label {
          cursor: pointer;
        }
      }
    }

    p {
      margin: 15px 5px;
    }

    h2 {
      margin: 15px 5px 15px 5px;
      line-height: 3.5rem;
    }
  }
}
