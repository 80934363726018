/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/
input[type="checkbox"] {
  margin: 3px;
}

input[type="checkbox"].margin-left {
  margin-left: -1.25rem;
}

.checkbox-button-theme {
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + .checkbox-button {
    cursor: pointer;
  }

  input[type="checkbox"]:disabled + .checkbox-button,
  input[type="checkbox"].disabled + .checkbox-button,
  .checkbox.disabled .checkbox-button {
    cursor: not-allowed;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 1 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-1 + .checkbox-button {
    background-color: #fff;
    border: 1px solid #c1caca;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
  }

  .checkbox-1 + .checkbox-button:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .checkbox-1:checked + .checkbox-button {
    background-color: #ecf2f7;
    border: 1px solid #92a1ac;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #243441;
  }

  .checkbox-1:checked + .checkbox-button:after {
    content: "\2715";
    position: absolute;
    top: 0px;
    left: 0px;
    color: #758794;
    width: 100%;
    text-align: center;
    font-size: 1.4em;
    padding: 1px 0 0 0;
    vertical-align: text-top;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 2 ---------------------------------------------------- */
  /**==================================================================== */
  input.checkbox-2 + .checkbox-button {
    background-color: #f37900;
    padding: 18px 20px 18px 23px;
    box-shadow: inset 0 50px 37px -30px rgba(255, 222, 197, 0.3), 0 0 13px rgba(0, 0, 0, 0.6);
    border-radius: 1000px;
    display: inline-block;
    position: relative;
    border-top: 1px solid #eca14f;
    margin-right: 30px;
    color: #fff;
    font-size: 1.7em;
    width: 213px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #552b09;
  }

  .checkbox-2 + .checkbox-button:hover {
    border-top: 1px solid #fc8c1e;
    background: #fc8c1e;
    box-shadow: inset 0 -50px 37px -30px rgba(255, 222, 197, 0.07), 0 0 13px rgba(0, 0, 0, 0.6);
  }

  .checkbox-2 + .checkbox-button:active {
    border-top: none;
    background: #fc8c1e;
    padding: 19px 20px 18px 23px;
    box-shadow: inset 0 3px 8px rgba(129, 69, 13, 0.3), inset 0 -50px 37px -30px rgba(255, 222, 197, 0.07),
      0 0 13px rgba(0, 0, 0, 0.6);
  }

  .checkbox-2 + .checkbox-button:after {
    content: " ";
    border-radius: 100px;
    width: 32px;
    position: absolute;
    top: 12px;
    right: 12px;
    box-shadow: inset 0px 16px 40px rgba(0, 0, 0, 0.4);
    height: 32px;
  }

  .checkbox-2 + .checkbox-button:before {
    content: " ";
    border-radius: 100px;
    width: 20px;
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 999;
    box-shadow: inset 0px 16px 40px #fff;
    height: 20px;
    display: none;
  }

  .checkbox-2:checked + .checkbox-button:before {
    display: block;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 3 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-3 + .checkbox-button {
    background-color: #fff;
    border: 1px solid #c1caca;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 1000px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
  }

  .checkbox-3 + .checkbox-button:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.2);
  }

  .checkbox-3:checked + .checkbox-button {
    background-color: #ecf2f7;
    border: 1px solid #92a1ac;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #243441;
  }

  .checkbox-3:checked + .checkbox-button:before {
    content: " ";
    border-radius: 100px;
    position: absolute;
    background: #253c4b;
    opacity: 0.8;
    display: none;
    box-shadow: inset 0 15px 23px -10px rgba(187, 230, 240, 0.3), 0 2px 2px rgba(0, 0, 0, 0.1);
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
  }

  .checkbox-3:checked + .checkbox-button:before {
    content: " ";
    display: block;
  }

  #checkbox-3-2:checked + .checkbox-button:before {
    top: 4px;
    left: 4px;
    width: 20px;
    height: 20px;
  }

  #checkbox-3-3:checked + .checkbox-button:before {
    top: 5px;
    left: 5px;
    width: 28px;
    height: 28px;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 4 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-4 + .checkbox-button {
    background-color: #fff;
    border: 2px solid #d6846a;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 5px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    margin-right: 30px;
  }

  .checkbox-4 + .checkbox-button:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .checkbox-4:checked + .checkbox-button {
    background-color: #f7f2ec;
    border: 2px solid #c47d62;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  }

  .checkbox-4 + .checkbox-button:after {
    position: absolute;
    top: 99px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    content: "\2715";
    color: #947975;
    width: 100%;
    text-align: center;
    font-size: 1.4em;
    padding: 1px 0 0 0;
    left: 0px;
  }

  .checkbox-4:checked + .checkbox-button:after {
    top: 0px;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 5 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-5 + .checkbox-button {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
    -webkit-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
  }

  .checkbox-5 + .checkbox-button:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .checkbox-5:checked + .checkbox-button {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  }

  .checkbox-5 + .checkbox-button:after {
    -webkit-transform: rotateX(270deg);
    -webkit-transform-origin: 0 0;
    transform: rotateX(270deg);
    transform-origin: 0 0;
    -webkit-transition: all 0.6s ease-in;
    transition: all 0.6s ease-in;
    position: absolute;
    top: 0px;
    left: 0px;
    content: "\2715";
    padding: 9px 0 0 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: #fff;
    font-size: 1.5em;
    line-height: 0;
    background: #cce7f7;
    color: #5c7fa0;
    text-align: center;
  }

  .checkbox-5:checked + .checkbox-button:after {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }

  .checkbox-5:checked + .checkbox-button {
    -webkit-transition: all 0.5s linear 0.35s;
    transition: all 0.5s linear 0.35s;
    background: #aaa;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 6 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-6 + .checkbox-button {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
    width: 15px;
    height: 15px;
    -webkit-transition: all 0.5s linear 0s;
    transition: all 0.5s linear 0s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 100%;
    transform-origin: 100% 100%;
  }

  .checkbox-6 + .checkbox-button:after {
    -webkit-transform: rotateX(90deg) rotateY(0deg);
    -webkit-transform-origin: 0 0;
    transform: rotateX(90deg) rotateY(0deg);
    transform-origin: 0 0;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    position: absolute;
    top: 0px;
    left: 0px;
    content: "\2715";
    padding: 15px 0 0 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: #fff;
    font-size: 1.5em;
    line-height: 0;
    background: #3c809b;
    color: #f0f6fc;
    text-align: center;
  }

  .checkbox-6 + .checkbox-button:before {
    position: absolute;
    top: 0px;
    left: 0px;
    content: " ";
    padding: 9px 0 0 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    background: #fff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .checkbox-6:checked + .checkbox-button:before {
    background: #ccc;
  }

  .checkbox-6:checked + .checkbox-button:after {
    -webkit-transform: rotateX(0deg) rotateY(0deg) !important;
    transform: rotateX(0deg) rotateY(0deg) !important;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 7 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-7 + .checkbox-button {
    background-color: #fff;
    padding: 6px 43px 8px 9px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    height: 17px;
    overflow: hidden;
    font-size: 1.3em !important;
    color: #464e50;
    -webkit-transition: all 0.3s linear, color 0 linear;
    transition: all 0.3s linear, color 0 linear;
  }

  .checkbox-7 + .checkbox-button:after {
    content: " ";
    position: absolute;
    background: #fff;
    top: 4px;
    right: 3px;
    width: 24px;
    display: block;
    font-size: 1em !important;
    height: 16px;
    border-radius: 3px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.6), inset 0 -18px 15px -10px rgba(0, 0, 0, 0.05);
    padding: 4px 0px;
    text-align: center;
    color: #496f7a;
  }

  .checkbox-7:checked + .checkbox-button {
    color: #fff;
  }

  .checkbox-7 + .checkbox-button > span {
    position: relative;
    z-index: 99999;
    font-family: "adelle-sans", sans-serif;
  }

  .checkbox-7:active + .checkbox-button:after {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.6), inset 0 2px 6px rgba(0, 0, 0, 0.1),
      inset 0 -18px 15px -10px rgba(0, 0, 0, 0.05);
  }

  .checkbox-7:checked + .checkbox-button:after {
    content: "\2715";
  }

  .checkbox-7 + .checkbox-button:before {
    content: " ";
    position: absolute;
    background: #6ac6dd;
    width: 0%;
    height: 100%;
    top: 0;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    left: 0;
  }

  .checkbox-7:checked + .checkbox-button:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 8 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-8 + .checkbox-button {
    background-color: #fff;
    padding: 9px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
    width: 40px;
    height: 15px;
  }

  .checkbox-8 + .checkbox-button:after {
    content: " ";
    position: absolute;
    background: #e6332c;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
  }

  .checkbox-8 + .checkbox-button:before {
    content: " ";
    position: absolute;
    background: white;
    top: 2px;
    left: 2px;
    z-index: 99999;
    width: 29px;
    height: 29px;
    border-radius: 100px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5), inset 0 -18px 15px -10px rgba(0, 0, 0, 0.05);
  }

  .checkbox-8 + .checkbox-button:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .checkbox-8:checked + .checkbox-button:before {
    content: " ";
    position: absolute;
    left: 27px;
    border-radius: 100px;
  }

  #checkbox-8-1 + .checkbox-button,
  #checkbox-8-2 + .checkbox-button {
    overflow: hidden;
  }

  .checkbox-8:checked + .checkbox-button:after {
    content: " ";
    font-size: 1.5em;
    position: absolute;
    background: #63c99c;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 9 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-9 + .checkbox-button {
    background-color: #fff;
    padding: 9px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
    width: 45px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
    height: 10px;
  }

  .checkbox-9 + .checkbox-button:before {
    content: " ";
    position: absolute;
    background: white;
    top: -1px;
    z-index: 99999;
    left: -1px;
    width: 21px;
    height: 30px;
    border-radius: 3px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.6), inset 0 -18px 15px -10px rgba(0, 0, 0, 0.05);
  }

  .checkbox-9 + .checkbox-button:after {
    content: "NO";
    position: absolute;
    top: 7px;
    left: 37px;
    font-size: 1.2em;
    color: #868686;
  }

  .checkbox-9:checked + .checkbox-button:after {
    content: "YES";
    left: 8px;
    color: #21be92;
  }

  .checkbox-9:checked + .checkbox-button:before {
    content: " ";
    position: absolute;
    z-index: 99999;
    left: 44px;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 10 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-10 + .checkbox-button {
    background-color: #fafbfa;
    padding: 9px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    margin-right: 1em;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    width: 3em;
    height: 15px;
    margin-bottom: -0.25em;
  }

  .checkbox-10 + .checkbox-button:after {
    content: " ";
    position: absolute;
    top: 0;
    -webkit-transition: box-shadow 0.1s ease-in;
    transition: box-shadow 0.1s ease-in;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    box-shadow: inset 0 0 0 0 #eee, 0 0 1px rgba(0, 0, 0, 0.4);
  }

  .checkbox-10 + .checkbox-button:before {
    content: " ";
    position: absolute;
    background: white;
    top: 1px;
    left: 1px;
    z-index: 999999;
    width: 1em;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    height: 1em;
    border-radius: 100px;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.05), 0 0px 1px rgba(0, 0, 0, 0.3);
  }

  .checkbox-10:active + .checkbox-button:after {
    box-shadow: inset 0 0 0 20px #eee, 0 0 1px #eee;
  }

  .checkbox-10:active + .checkbox-button:before {
    width: 1.2em;
  }

  .checkbox-10:checked:active + .checkbox-button:before {
    width: 1.2em;
    left: 1.8em;
  }

  .checkbox-10 + .checkbox-button:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .checkbox-10:checked + .checkbox-button:before {
    content: " ";
    position: absolute;
    left: 2em;
    border-radius: 100px;
  }

  .checkbox-10:checked + .checkbox-button:after {
    content: " ";
    font-size: 1.5em;
    position: absolute;
    background: #4cda60;
    box-shadow: 0 0 1px #4cda60;
  }

  /**==================================================================== */
  /**CHECKBOX TYPE 11 ---------------------------------------------------- */
  /**==================================================================== */
  .checkbox-11 + .checkbox-button {
    background-color: #fff;
    padding: 11px 9px;
    border-radius: 7px;
    display: inline-block;
    position: relative;
    margin-right: 30px;
    background: #f7836d;
    width: 55px;
    height: 10px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1), 0 0 10px rgba(245, 146, 146, 0.4);
  }

  .checkbox-11 + .checkbox-button:before {
    content: " ";
    position: absolute;
    background: #fff;
    top: 0px;
    z-index: 99999;
    left: 0px;
    width: 24px;
    color: #fff;
    height: 32px;
    border-radius: 7px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
  }

  .checkbox-11 + .checkbox-button:after {
    content: "OFF";
    position: absolute;
    top: 7px;
    left: 37px;
    font-size: 1.2em;
    color: white;
    font-weight: bold;
    left: 8px;
    padding: 5px;
    top: 4px;
    border-radius: 100px;
  }

  .checkbox-11:checked + .checkbox-button {
    background: #67a5df;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1), 0 0 10px rgba(146, 196, 245, 0.4);
  }

  .checkbox-11:checked + .checkbox-button:after {
    content: "ON";
    left: 6px;
  }

  .checkbox-11:checked + .checkbox-button:before {
    content: " ";
    position: absolute;
    z-index: 99999;
    left: 49px;
  }

  .checkbox-11 + .checkbox-button:after {
    left: 35px;
  }
}
