/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/
//colors
$white: #fff;
$default: #373a3c;
$color_apple_approx: #42a948;
$color_apple_blossom_approx: #a94442;
$color_rangoon_gray_approx: #1c1c1c;
$color_picton_blue_approx: #35acdf;
$color_razzmatazz_approx: #ec1b5a;
$color_razzmatazz_approx_dark: #843534;
$color_jordy_blue_approx: #79aefe;
$color_jordy_blue_approx_dark: #2d80fd;
$color_chambray_approx: #314190;
$color_forest_green_approx: #279636;
$color_old_lavender_approx: #7d5d81;
$color_ronchi_approx: #ead24c;
$color_jacarta_approx: #2d2366;
$color_alto_approx: #ddd;
$color_log_cabin_approx: #222;
$alabaster_80: rgba(250, 250, 250, 0.8);
$blue: blue;
$black: #000000;
$color_flush_orange_approx: #ff8000;
$color_mint_green_approx: #a0ffa0;
$color_celeste_approx: #cccccc;
$color_salem_approx: #008457;
$color_gallery_approx: #eceeef;
$light_bg_color: #8fadab;
$navbar_color: #8fad93;
$navbar_color_opacity: rgba(143, 173, 147, 0.6);
$navbar_color-dark: #6e6e6e;
$footer_color: #5d716f;
$color_bg_gray: #e6e3e3;
$color_gray_opacity: rgba(0, 0, 0, 0.6);
$color_white_opacity: rgba(255, 255, 255, 0.5);
$color_primary_blue: #0275d8;
$navbar-toggler-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !default;
$navbar-toggler-border: $white !default;
//transitions
$transition-ease-all-5: all 0.5s ease;
$transition-ease-width-2: width 0.2s ease-in;
//fonts
$font_0: Courier New;
$font_1: Courier;
$font_2: monospace;
$font_link: Poppins, sans-serif;
$font_text: Roboto, sans-serif;
// size
$sidebar-width: 300px;
$vcc-sidebar-width: 287px;
