/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: amaggi
*/
.conference-settings {
  display: flex;
  flex-direction: column;
  align-items: center;

  span[dropdown] .dropdown-menu {
    top: auto !important;
  }
  .dropdown-menu .dropdown-item {
    white-space: normal;
  }
}
.user-control {
  display: flex;
  justify-content: space-evenly;
  padding-top: 1rem;
}

.user-control,
.media-device-settings,
.virtual-background,
.troubleshoot-control,
.send-logs {
  margin-bottom: 1rem;
  width: 100%;
}

.testing-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.volume {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.settings-slider {
  width: calc(100% - 60px) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 16px !important;
}

.settings-slider.ngx-slider .ngx-slider-pointer {
  width: 14px;
  height: 14px;
  background-color: #007bff;
  top: auto;
  bottom: -5px;
  outline: none;
}

.settings-slider.ngx-slider .ngx-slider-pointer::after {
  display: none;
}

.settings-slider.ngx-slider .ngx-slider-bubble {
  bottom: 10px;
}

.settings-slider.ngx-slider .ngx-slider-selection {
  background: #007bff;
}

.camera-rotation {
  display: flex;
  align-items: center;
}

.camera-rotation select {
  margin-left: 5px;
  width: 20%;
}

.logs-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 5px
}

.logs-switches {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px
}

#settings-panel label {
  cursor: pointer;
}
