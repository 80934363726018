/**
* Copyright Compunetix Incorporated 2016-2022
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
       * Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: amaggi
*/

// This scss file is used to override css rules already present
// in other files in case you want to specify a particular behavior for CW.
// The css rules must be inserted inside .cw {...}
//
// For example the following rule:
//
// .cw {
//   #conference-video-container {
//    top: 10rem;
//   }
// }
//
// will be compiled like this:
//
// .cw #conference-video-container {
//   top: 10rem;
// }
//
// overwriting #conference-video-container in _conference.scss

@import "constants";
@import "media-queries";

.cw {


}
