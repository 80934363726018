/**
Copyright Compunetix Incorporated 2016
All rights reserved
This document and all information and ideas contained within are the
property of Compunetix Incorporated and are confidential.

Neither this document nor any part nor any information contained in it may
be disclosed or furnished to others without the prior written consent of:
Compunetix Incorporated
2420 Mosside Blvd
Monroeville, PA 15146
http://www.compunetix.com

Author: lcheng
*/
.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.vertical-top {
  vertical-align: top;
}

.vertical-bottom {
  vertical-align: bottom;
}

.vertical-middle {
  vertical-align: middle;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.nopadding {
  padding: 0;
}

.left-padding-only {
  padding: 0;
  padding-left: 3px;
}

.inline-block {
  display: inline-block;
}

.pull-center {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.cursor-normal {
  cursor: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.form-control-lg {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.invisible {
  visibility: hidden;
}

.tag-pill {
  border-radius: 50%;
  padding: 5px 7px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10em;
}

.narrow {
  max-width: 780px;
}

.btn-round {
  &.btn-lg {
    height: 16em;
    line-height: 4em;
    width: 16em;
    padding: 6em 0 6em 0;
    border-radius: 8em;
    font-size: 2.5em;
    box-shadow: 0px 0px 30px #888888;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }
}

.no-overflow {
  overflow: hidden !important;
}

.no-padding {
  padding: 0px;
}

.fill-up-right-of-btn {
  padding-left: 0px;
  padding-right: 60px;
  display: inline-block;
  margin-right: -60px;
}

.navbar .navbar-toggler {
  background-image: $navbar-toggler-bg;
  border-color: $navbar-toggler-border;
}

.top {
  top: 0px;
}

.bottom {
  bottom: 0px;
}

.expand-to-top {
  top: 0rem !important;
}

.expand-to-bottom {
  bottom: 0px !important;
}

.margin-top {
  margin-top: 0.5em;
}

.py-05 {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

.py-075 {
  padding-top: 0.75em !important;
  padding-bottom: 0.75em !important;
}

.p-075 {
  padding: 0.75em !important;
}

.mt-n06 {
  margin-top: -0.6em !important;
}

.absolute-right {
  position: absolute;
  right: 2em;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.wrapOrHide {
  white-space: normal !important;
  overflow-x: hidden !important;
}

.token-box {
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 50vh;
  overflow: auto;
}
.pr-4 {
  padding-right: 4rem !important;
}
