/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/
#footer-nav {
  border-radius: 0px;
  overflow: hidden;
  white-space: nowrap;
  border-style: solid;
  border-width: 0rem;

  .float-sm-left,
  .float-sm-right {
    position: absolute;
  }
  .copyright {
    .nav-link {
      margin: 0px;
      color: $white;
    }

    font-size: 0.9rem;

    @include tablet-portrait-and-up {
      font-size: 1rem;
    }
  }

  .copyright.mobile {
    font-size: 10px;

    .nav-link {
      padding: 0;
    }
  }

  .copyright-menu {
    width: 100%;

    @include tablet-portrait-and-up {
      width: auto;
    }

    .copyright {
      margin-top: 0.1rem;
      font-size: 0.8rem;
      width: 100%;

      @include tablet-portrait-and-up {
        width: auto;
      }
    }
  }
}

#help-intro-popover-content,
#help-popover-content {
  background: $navbar_color_opacity;
  color: $white;

  .popover-arrow::after {
    border-left-color: $navbar_color_opacity;
  }

  .popover-title {
    background: $navbar_color_opacity;
  }
}

#help-chat-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
}

#help-popover-content,
#help-intro-popover-content {
  position: fixed;
  z-index: 9999;
  width: 20rem;

  &.popover-left .popover-arrow.arrow-bottom {
    top: auto;
    bottom: 15px;
  }
}

#help-button {
  img {
    width: 60px;
    height: 60px;
  }

  .badge-pill {
    top: 2px;
    right: 15px;
    position: absolute;
  }
}
