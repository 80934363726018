/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/
#main-top-nav-header {
  border-radius: 0px;
  z-index: 1040;
  height: 2rem;
  padding: 0 10px;

  .nav-item {
    margin: 0px;
    .nav-link {
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 0.9rem;
      color: white;
      label {
        cursor: pointer;
      }
    }
  }

  ul.float-sm-right {
    margin-right: 1rem;
  }
}

#main-top-nav-container {
  top: 2rem;
  border-radius: 0px;

  .nav-item {
    .navbar-brand {
      margin-left: 20px;
      margin-right: 120px;
    }

    .nav-link {
      margin-top: 0.5rem;
    }
  }
}

.top-nav-language {
  img {
    height: 1rem;
  }

  .dropdown-menu {
    z-index: 1040;
    margin-left: 10px;
  }

  li {
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }
}

.top-nav-theme {

  margin-left: 10px;

  .dropdown-menu {
    z-index: 1040;
    margin-left: 93px;
  }

  li {
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }
}

#bd-main-nav {
  max-height: 260px;
  overflow-y: auto;
  justify-content: space-between;
}

.top-nav-control-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: #fff;

  .top-nav-control {
    width: 180px;
    position: relative;
    pointer-events: all;
  }

  .btn {
    color: $white;
    padding: 0;

    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }

    &.tag {
      padding: 0.25rem 0px;
    }
  }

  .tag {
    font-size: 0.9rem;
    font-weight: 700;
  }

  .badge {
    width: 100%;
  }
  .disabled-badge {
    border-radius: 5px;
    box-shadow: 0 0 10px #fff;
  }

  .dropdown-toggle {
    cursor: pointer;
    border: none;
    border-radius: 5px;
    height: 16px;
    box-shadow: 0 0 10px #fff;
  }

  .dropdown-menu {
    width: 100%;
    background-color: rgb(65, 64, 66);
  }

  .dropdown-item {
    cursor: pointer;
    color: white;
  }

  .dropdown-item:focus, .dropdown-item:hover {
    color: #2b2d2f;
    text-decoration: none;
    background-color: #f5f5f5;
  }
}

.top-nav-link {
  flex-direction: row;
  label {
    margin-bottom: 0;
    margin-left: 10px;
  }
}
