/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/

#settings-panel,
#menu-panel {
  .row {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  #user-control {
    a {
      color: $default;

      &:hover,
      &:focus {
        color: $color_celeste_approx;
      }
    }

    img {
      height: 28px;
    }
  }
}

.participant-panel,
#operator-panel,
#settings-panel,
.side-panel,
#menu-panel {
  position: absolute;
  left: 0px;
  width: $sidebar-width;
  max-width: 100%;
  z-index: 9;
  overflow-y: auto;
  overflow-x: hidden;
  top: 7rem;
  bottom: 3rem;

  .close {
    position: absolute;
    right: 6px;
    top: -6px;
    font-size: 2rem;
  }

  .panel-header-title {
    font-size: 1.75rem;
  }

  .reset-btn {
    margin-left: -3rem;
    color: #ccc;
  }

  &.position-right {
    left: auto;
    right: 0px;
  }

  i.icon-large {
    font-size: 2rem;
    padding: 0px 4px;

    &.fa-mobile {
      font-size: 3rem;
    }
  }

  label, a {
    padding: 0px 3px;
    vertical-align: middle;
    margin-bottom: 0px;
    &.badge-pill {
      padding: 1px 5px;
      color: white;
    }
  }

  #video-terminal-image {
    height: 30px;
    width: 30px;
    margin-top: -15px;
  }

  .panel-header {
    padding: 10px 0px;
    .counter-tag {
      position: absolute;
      right: 2rem;
      top: 0.5rem;
      padding: 0;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      line-height: 1.55rem;
    }
  }

  .panel-summary {
    .total {
      position: absolute;
      right: 20px;
      bottom: 0px;
    }
  }

  .panel-participant-list,
  .panel-operator-list {
    .btn-group {
      .btn {
        label {
          margin-bottom: 0px;
        }
      }
    }

    .list-group {
      //width: calc(#{$sidebar-width} - 24px);
      //margin-left: 10px;
      margin: 0;

      .btn-control {
        padding: 0.4rem 0px;
        margin: 3px;
        width: 125px;
        font-size: 12.5px;

        &.disabled {
          color: #adb5bd;
          border: 1px solid #adb5bd;
        }
      }
    }

    .list-group-item {
      //margin-bottom: 5px;
      border-radius: 0;
    }

    .participant-list-row {
      white-space: nowrap;
      font-size: 1.1rem;
      .tag {
        white-space: normal;
      }
      .pad {
        padding: 0.5rem 0;
      }
      i {
        font-size: 1.5rem;
        padding: 0px 5px;
        width: 2rem;
      }

      img {
        height: 1.5rem;
        margin-top: -0.5rem;
      }

      .name-section,
      .control-section,
      .chat-section {
        white-space: nowrap;
        padding-left: 0px;
        padding-right: 0px;
      }

      .control-section {
        .btn-link:focus {
          outline: none;
        }

        .btn-link:active {
          transform: translateY(1px);
        }
      }

      .present-section {
        display: flex;
        justify-content: flex-end;
      }
      button {
        vertical-align: middle;
        padding: 0 2px;
      }
    }
  }

  .guest-item-container {
    .info-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    .voice-badge {
      font-size: 0.7rem;
      margin-top: 2px;
      max-width: 18px;
      white-space: break-spaces;
    }

    .avatar {
      margin-left: 5px;
      cursor: pointer;
    }

    .name-section {
      label {
        margin-left: 5px;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }

    .control-section {
      a {
        font-size: 1.5rem;
        margin-left: 4px;
      }
    }

    .present-section {
      text-align: right;
    }
  }

  .name-string {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1;
  }

  .time-string {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .socket-inactive {
    width: 6px;
    height: 6px;
    min-width: 6px;
    border-radius: 50%;
    background-color: orangered;
  }

  .socket-active {
    width: 6px;
    height: 6px;
    min-width: 6px;
    border-radius: 50%;
    background-color: forestgreen;
  }

  .buttons-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 262px;
  }

  .whispering-button {
    background-color: #28a745;
    color: white;
  }

  .panel-participant-list{
    .tag {
      white-space: normal;
      //margin-right: 0.5rem;
      max-width: 70px;
      font-size: 0.75rem;
      line-height: 1.1;
      font-weight: 700;

      p {
        margin-bottom: 0px;
      }
    }
    .search-container {
      height: 2.4rem;
    }
    .participants-container {
      bottom: 0rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .avatar {
    &.fallback {
      background-image: url(/images/avatar.png);
      display: inline-block;
      min-width: 35px;
      min-height: 35px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .avatar-image {
      min-width: 35px;
      min-height: 35px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.tag {
      font-size: 2em;
      height: 45px;
    }
  }
}

.participant-panel.mobile,
#settings-panel.mobile,
#menu-panel.mobile,
#chatroom-window {
  width: $sidebar-width;
  max-width: 100%;
}

.participant-panel.mobile,
#settings-panel.mobile,
#menu-panel.mobile,
#chatroom-window.mobile {
  top: 0;
  border: none;
  box-shadow: 0 12px 10px rgba(0, 0, 0, 0.3);

  .close {
    font-size: 3rem;
    margin-right: -7px;
    margin-top: -3px;
  }

  .close i {
    font-size: 3rem !important;
  }

  h2 {
    font-size: 3rem;
    line-height: 2.5;
  }

  .panel-header {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
  }

  #user-control {
    padding-top: 15px;
  }

  #user-control a {
    background-color: #f3f3f3;
    border-radius: 0.7rem;
    padding: 15px;
  }

  #media-device-settings button {
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
  }
  .btn {
    font-size: 2rem;
  }
  .dropdown-toggle label {
    width: 60%
  }
  .dropdown-item.btn {
    min-height: 30px;
    line-height: 30px;
  }

  #resolution-dropdown,
  #bandwidth-dropdown,
  #troubleshoot-control,
  #video-aspect-dropdown {
    margin-top: 14px;
  }

  .secondary-camera {
    display: none;
  }
}

#settings-panel.mobile {
  .row {
    padding-top: 2.5rem;
  }
}

#menu-panel.mobile {
  z-index: 8;

  .btn {
    font-size: 3rem;
    color: black;

    .tag {
      top: -7px;
      left: -14px;
    }
  }
}
#chatroom-window.mobile {
  .close {
    padding: 0 2px;
    vertical-align: middle;
    margin-bottom: 0;
  }

  .chatroom-chatbox {
    font-size: 1.5rem;
  }

  .message-block {
    font-size: 1.5rem !important;
  }

  input {
    font-size: 2rem;
  }
}

.participant-panel.mobile {
  .panel-participant-list {
    font-size: 1.5rem;

    input {
      font-size: 1.7rem;
    }

    .participant-list-row {
      margin: 0 !important;
    }

  }
}


.popover-content.chat.mobile {
  font-size: 1.5rem;

  .btn {
    font-size: 1.5rem;
  }
}

.guest-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
