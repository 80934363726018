/**
* Copyright Compunetix Incorporated 2016-2018
* All rights reserved
* This document and all information and ideas contained within are the
* property of Compunetix Incorporated and are confidential.
*
* Neither this document nor any part nor any information contained in it may
* be disclosed or furnished to others without the prior written consent of:
* Compunetix Incorporated
* 2420 Mosside Blvd
* Monroeville, PA 15146
* http://www.compunetix.com
*
* Author: lcheng
*/

@import "constants";
@import "media-queries";

#loopback-video-container {
  display: block;
  position: absolute;
  z-index: 8;
  background-color: transparent;
  max-width: 33%;
  top: 86px;
  right: 1rem;
  width: 10rem;

  @include desktop-and-up {
    width: 18rem;
    top: 93px
  }

  &.max-window {
    max-width: 90%;

    width: 16rem;

    @include tablet-landscape-and-up {
      width: 48rem;
    }
  }
}

#loopback-video-container.loopback-mobile {
  top: 0 !important;
  left: 0 !important;
  width: 110px;
  height: auto;
}

.mirror {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

#loopback-control-container {
  position: fixed;
  display: block;
  top: 2px;
  left: 50%;
  right: 50%;
  margin-left: -90px;
  width: 180px;
  max-height: 30px;
  z-index: 1050;
  border-radius: 5px;
  color: $white;
  line-height: 1.5;

  .btn {
    color: $white;
    padding: 0px;

    &:hover,
    &:focus {
      color: $color_celeste_approx;
    }

    &.tag {
      padding: 0.25rem 0px;
    }
  }

  .tag {
    font-size: 100%;
    font-weight: 700;
  }

  .dropdown-toggle {
    cursor: pointer;
    border: none;
  }

  .dropdown-menu {
    width: 100%;
    background-color: rgb(65, 64, 66);
  }

  .dropdown-item {
    cursor: pointer;
    color: white;
  }

  .dropdown-item:focus, .dropdown-item:hover {
    color: #2b2d2f;
    text-decoration: none;
    background-color: #f5f5f5;
  }
}
